import React, { useEffect, useState } from "react";
import { Button, Modal, notification, Select } from "antd";
import { getApi } from "../../utils/getApiMethod";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AiOutlineBell, AiOutlineMail, AiOutlineUser } from "react-icons/ai";
import { FaSignal, FaSlack, FaWhatsapp } from "react-icons/fa";
import { BsClockHistory, BsExclamationCircle } from "react-icons/bs";
import FormComponent from "../../components/Formcomponent";
import { IoClose } from "react-icons/io5";
import { Editor } from "@monaco-editor/react";
import { putApi } from "../../utils/putApiMethod";
import { postApi } from "../../utils/postApiMethod";
import { MdOutlineConfirmationNumber } from "react-icons/md";
import { deleteApi } from "../../utils/deleteApiMethod";

const schema = yup.object().shape({
  alarmTitle: yup.string().required("Incident Title is required"),
  operator: yup.string().required("Operator is required"),
  channels: yup.array().min(1, "At least one channel must be selected"),
  alertto: yup.array().min(1, "At least one alert option must be selected"),
  frequencyunit: yup.string().required("Frequency Unit is required"),
  severity: yup.string().required("Severity is required"),
});
const { Option } = Select;

const SubMetrics = ({ selSubMetric, handleBackClick, setConfigureStarted }) => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      alarmTitle: "",
      operator: "",
      channels: "",
      alertto: "",
      frequencyunit: "",
      severity: "",
    },
  });
  const [query, setQuery] = useState("");
  const [queryResult, setQueryResult] = useState("");
  const [MetricIdForSetNotification, setMetricIdForSetNotification] =
    useState("");
  const [visibleSetNotification, setVisibleSetNotification] = useState(false);
  const [setNotification, setSetNotification] = useState(false);
  const [contactList, setContactsList] = useState([]);

  const [connectedDatabases, setConnectedDatabases] = useState([]);

  const getDBConfig = async () => {
    try {
      const url = "dbconfig/resource";
      const result = await getApi(url);
      if (result?.data?.rows) {
        setConnectedDatabases(result?.data?.rows);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const url = "dbconfig/resource";
    getDBConfig(url);
  }, []);

  const [connectedFTPs, setConnectedFTPs] = useState([]);

  const getFTPConfig = async (url) => {
    try {
      const result = await getApi(url);
      if (result?.data?.rows) {
        setConnectedFTPs(result?.data?.rows);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const url = "ftpconfig/resource";
    getFTPConfig(url);
  }, []);

  const handleRunQuery = async () => {
    if (!selectedDatabase) {
      notification.warning({
        message: "Database Not Selected",
        description: "Please select a database before writing a query.",
      });
      return;
    }

    const selectedDatabaseInfo = connectedDatabases.find(
      (db) => db.dbname === selectedDatabase
    );

    const payload = {
      query: query,
      metricname: selSubMetric.metricname,
      sourceid: selectedDatabaseInfo.sourceid,
      SID: selSubMetric.SID,
      id: selSubMetric.id,
    };
    // console.log("payload", payload);
    const url = `update-metric`;
    try {
      const response = await putApi(url, payload);
      if (response?.statusCode === 200) {
        notification.success({
          message: response.message,
          // description: error.message,
        });
        setQueryResult(
          response?.data?.queryResult || `Query Execution Failed...`
        );
        setMetricIdForSetNotification(response?.data?.metricid || "");
        setVisibleSetNotification(true);
        setConfigureStarted(false);
      }
    } catch (error) {
      console.error("Failed:", error.message);
      notification.error({
        message: error.message,
      });
    }
  };

  const handleNotification = () => {
    if (visibleSetNotification) {
      setSetNotification(true);
    } else {
      notification.warning({
        message: "Check query result before setup notification",
      });
    }
  };

  const [selectedDatabase, setSelectedDatabase] = useState(null);
  const [tempDatabase, setTempDatabase] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleDatabaseChange = (value) => {
    setIsModalVisible(true);
    setTempDatabase(value);
  };

  const handleOk = () => {
    setSelectedDatabase(tempDatabase);
    setIsModalVisible(false);
    const newQuery = selSubMetric.query || "No query Found";
    setQuery(newQuery);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleConfigureDb = () => {
    // console.log("Configure DB clicked");
    navigate("/app/dbconfig");
    setConfigureStarted(false);
  };

  const [alertListRes, setAlertListRes] = useState([]);

  const getAlertLists = async (url) => {
    try {
      const result = await getApi(url);
      if (result?.data) {
        setAlertListRes(result?.data?.rows);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (setNotification) {
      const url = `alertconfig/resource?column=metricid&value=${MetricIdForSetNotification}`;
      getAlertLists(url);
    }
  }, [setNotification, MetricIdForSetNotification]);

  const onSubmit = async (data) => {
    //  console.log("data", data);
    if (data.severity === "high") {
      data.weightage = 100;
    } else if (data.severity === "medium") {
      data.weightage = 60;
    } else if (data.severity === "low") {
      data.weightage = 30;
    }
    const undefinedValues = {};
    const modifiedData = {
      ...data,
      metricid: MetricIdForSetNotification,
      submetricname: selSubMetric.metricname || "",
    };
    Object.keys(modifiedData).forEach((key) => {
      if (modifiedData[key] === undefined) {
        undefinedValues[key] = modifiedData[key];
        delete modifiedData[key];
      }
    });
    // console.log("modifiedData", modifiedData);
    // console.log("undefinedValues", undefinedValues);
    const url = `alertconfig/resource`;
    try {
      const response = await postApi(url, modifiedData);
      if (response?.statusCode === 200) {
        notification.success({
          message: response.message,
          // description: error.message,
        });
        reset();
        const url = `alertconfig/resource?column=metricid&value=${MetricIdForSetNotification}`;
        getAlertLists(url);
      }
    } catch (error) {
      console.error("Failed:", error.message);
      notification.error({
        message: error.message,
      });
    }
  };

  const handleRemoveAlarm = async (ele) => {
    // console.log("remove clicked...", ele);
    const url = `alertconfig/resource/${ele.id}`;
    try {
      const response = await deleteApi(url);
      if (response?.statusCode === 200) {
        notification.success({
          message: response.message,
          // description: error.message,
        });
        const url = `alertconfig/resource?column=metricid&value=${MetricIdForSetNotification}`;
        getAlertLists(url);
      }
    } catch (error) {
      console.error("Failed:", error.message);
      notification.error({
        message: error.message,
      });
    }
  };

  const formFields = [
    {
      name: "alarmTitle",
      label: "Alarm Title",
      type: "input",
      icon: AiOutlineBell,
    },
    {
      name: "operator",
      label: "Operator",
      type: "select",
      icon: FaSignal,
      options: [
        {
          value: "less_than",
          label: "Less Than",
          icon: FaSignal,
          color: "blue",
        },
        {
          value: "equal_to",
          label: "Equal To",
          icon: FaSignal,
          color: "green",
        },
        {
          value: "greater_than",
          label: "Greater Than",
          icon: FaSignal,
          color: "red",
        },
        { value: "between", label: "Between", icon: FaSignal, color: "orange" },
      ],
    },
    {
      name: "range",
      label: "Range",
      type: "input",
      conditional: "greater_than",
    },
    {
      name: "range",
      label: "Range",
      type: "input",
      conditional: "equal_to",
    },
    {
      name: "range",
      label: "Range",
      type: "input",
      conditional: "less_than",
    },
    {
      name: "startValue",
      label: "Start Value",
      type: "input",
      conditional: "between",
    },
    {
      name: "endValue",
      label: "End Value",
      type: "input",
      conditional: "between",
    },
    {
      name: "channels",
      label: "Channels",
      type: "select",
      icon: FaWhatsapp,
      options: [
        {
          value: "whatsapp",
          label: "WhatsApp",
          icon: FaWhatsapp,
          color: "green",
        },
        { value: "email", label: "Email", icon: AiOutlineMail, color: "blue" },
        { value: "slack", label: "Slack", icon: FaSlack, color: "purple" },
      ],
      multiple: true,
    },
    {
      name: "alertto",
      label: "Alert To",
      type: "select",
      icon: AiOutlineUser,
      // options: [
      //   {
      //     value: 3,
      //     label: "Priyanshu",
      //     icon: AiOutlineUser,
      //     color: "black",
      //   },
      //   { value: 1, label: "Sasi", icon: AiOutlineUser, color: "brown" },
      //   { value: 2, label: "Agila", icon: AiOutlineUser, color: "gray" },
      // ],
      options: contactList,
      multiple: true,
    },
    {
      name: "frequencyunit",
      label: "Frequency Unit",
      type: "select",
      icon: BsClockHistory,
      options: [
        { value: "day", label: "Day", icon: BsClockHistory, color: "orange" },
        { value: "hour", label: "Hour", icon: BsClockHistory, color: "red" },
      ],
    },
    {
      name: "frequency",
      label: "Frequency",
      type: "input",
      icon: MdOutlineConfirmationNumber,
    },
    {
      name: "severity",
      label: "Severity",
      type: "select",
      icon: BsExclamationCircle,
      options: [
        {
          value: "high",
          label: "High",
          icon: BsExclamationCircle,
          color: "red",
        },
        {
          value: "medium",
          label: "Medium",
          icon: BsExclamationCircle,
          color: "yellow",
        },
        {
          value: "low",
          label: "Low",
          icon: BsExclamationCircle,
          color: "green",
        },
      ],
    },
    {
      name: "weightage",
      label: "Weightage",
      type: "input",
      conditional: "severity",
    },
    // {
    //   name: "weightage",
    //   label: "Weightage",
    //   type: "input",
    //   conditional: "high",
    // },
    // {
    //   name: "weightage",
    //   label: "Weightage",
    //   type: "input",
    //   conditional: "medium",
    // },
    // {
    //   name: "weightage",
    //   label: "Weightage",
    //   type: "input",
    //   conditional: "low",
    // },
  ];

  const getContactsList = async (url) => {
    try {
      const result = await getApi(url);
      if (result?.data?.rows) {
        const options = result?.data?.rows.map((item) => ({
          value: item.id,
          label: item.name,
          icon: AiOutlineUser,
          color: "black", // You can set a color or customize it
        }));
        setContactsList(options);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const url = "contact/resource";
    getContactsList(url);
  }, []);

  return (
    <>
      {setNotification ? (
        <div className="flex space-x-1 mr-2" style={{ height: "100vh" }}>
          <div className="flex-1 flex flex-col border border-gray-300 m-2 rounded-md overflow-hidden">
            <div className="p-2 flex items-center justify-between border-b border-gray-300 sticky top-0 bg-white z-10">
              <h2 className="cus-h2-heading">
                {`Configure Alarms for a ${selSubMetric.metricname}`}
              </h2>
              <Button
                onClick={() => {
                  setSetNotification(false);
                  reset();
                }}
              >
                Discard
              </Button>
            </div>
            <div
              className="flex-1 space-x-6 overflow-y-auto"
              style={{ height: "100vh" }}
            >
              <div className="p-3">
                <FormComponent
                  formFields={formFields}
                  handleSubmit={handleSubmit}
                  control={control}
                  errors={errors}
                  onSubmit={onSubmit}
                  buttonName={"Submit"}
                />
              </div>
            </div>
          </div>

          <div className="flex-1 flex flex-col border border-gray-300 m-2 rounded-md overflow-hidden">
            <div className="p-3 flex items-center justify-between border-b border-gray-300 sticky top-0 bg-white z-10">
              <h2 className="cus-h2-heading">Created Alarms</h2>
            </div>
            <div className="m-2">
              {alertListRes.length > 0 ? (
                <ul>
                  {alertListRes.map((ele, index) => (
                    <li
                      key={index}
                      className="p-2 m-2 border border-gray-200 rounded-lg bg-gradient-to-r from-blue-50 to-white shadow-md cursor-pointer transition-transform transform flex justify-between items-center"
                    >
                      <span className="flex-grow">{ele.message}</span>
                      <button
                        className="ml-4 text-red-600 hover:text-red-800 transition-colors p-1 rounded-full hover:bg-red-100"
                        onClick={() => handleRemoveAlarm(ele)}
                      >
                        <IoClose size={22} />
                      </button>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="p-2 m-2 border border-gray-200 rounded-lg bg-gradient-to-r from-blue-50 to-white shadow-md cursor-pointer transition-transform transform hover:scale-105 hover:bg-blue-50">
                  No Alarms created yet.
                </p>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex" style={{ height: "100vh" }}>
          <div className="flex-1 flex flex-col border border-gray-300 m-2 rounded-md overflow-hidden">
            <div className="p-2 flex items-center justify-between border-b border-gray-300 sticky top-0 bg-white z-10">
              <h3 className="text-lg font-semibold">
                {selSubMetric.metricname}
              </h3>
              <Button onClick={() => handleBackClick()}>Back</Button>
            </div>

            <div className="flex mt-2 mx-1">
              <div>
                <Select
                  placeholder="Select a database"
                  value={selectedDatabase}
                  onChange={handleDatabaseChange}
                  style={{ width: 200, marginBottom: 20 }}
                >
                  {[...connectedDatabases, ...connectedFTPs].map(
                    (item, index) => (
                      <Option key={index} value={item.dbname || item.filename}>
                        {item.dbname || item.filename}
                      </Option>
                    )
                  )}
                </Select>
              </div>
              <div className="ml-auto mr-1">
                <Button onClick={handleConfigureDb}>Configure DB</Button>
              </div>
            </div>
            <div className="flex-1 p-1 overflow-auto">
              <Editor
                height="calc(78vh - 60px)"
                language="sql"
                value={query}
                onChange={(newValue) => setQuery(newValue)}
                options={{
                  readOnly: !selectedDatabase,
                  wordWrap: "on",
                  minimap: { enabled: false },
                  scrollBeyondLastLine: false,
                }}
              />
              <div className="flex">
                <p className="ml-2 com-heading">SQL</p>
                <Button
                  type="primary"
                  onClick={handleNotification}
                  className="w-32 ml-auto mr-2"
                >
                  SetUpNotification
                </Button>
                <Button
                  type="primary"
                  onClick={handleRunQuery}
                  className="w-20 ml-auto mr-5"
                >
                  Run Query
                </Button>
              </div>
            </div>
          </div>

          <div className="flex-1 flex flex-col border border-gray-300 m-2 rounded-md overflow-hidden">
            <div className="p-3 flex items-center justify-between border-b border-gray-300 sticky top-0 bg-white z-10">
              <h2 className="cus-h2-heading">Query Result</h2>
            </div>
            <div className="flex-1 p-3">
              {queryResult || "Query result will be displayed here."}
            </div>
          </div>
        </div>
      )}
      <Modal
        title="Confirm Database Switch"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Continue"
        cancelText="Cancel"
      >
        <p>
          Switching data sources will reset your query in queryEditor. Are you
          sure you want to continue?
        </p>
      </Modal>
    </>
  );
};

export default SubMetrics;
