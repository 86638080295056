import React, { useState } from "react";
import { AiOutlineDollar, AiOutlineSafetyCertificate } from "react-icons/ai";
import { BiUser } from "react-icons/bi";
import { LiaMoneyBillAltSolid } from "react-icons/lia";
import { MdOutlineInventory2, MdOutlineShoppingCart } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  FaArrowTrendDown,
  FaArrowTrendUp,
  FaCircleChevronDown,
} from "react-icons/fa6";
import { GoDotFill } from "react-icons/go";

const Dashboard = () => {
  const navigate = useNavigate();

  const data = [
    {
      title: "Cost",
      value: "33.3K",
      description: "lower than same time yesterday",
      icon: <AiOutlineDollar className="text-xl text-green-500" />,
      iconbg: "bg-green-100",
      change: { percent: 8, direction: "down" },
      path: "cost",
    },
    {
      title: "Inventory",
      value: "68K",
      description: "lower than same time yesterday",
      icon: <MdOutlineInventory2 className="text-xl text-orange-500" />,
      iconbg: "bg-orange-100",
      change: { percent: 10, direction: "up" },
      path: "inventory",
    },
    {
      title: "Safety",
      value: "2",
      description: "lower than same time yesterday",
      icon: <AiOutlineSafetyCertificate className="text-xl text-yellow-500" />,
      iconbg: "bg-yellow-100",
      change: { percent: 15, direction: "down" },
      path: "safety",
    },
    {
      title: "Operations",
      value: "6% OTIF",
      description: "lower than same time yesterday",
      icon: <MdOutlineShoppingCart className="text-xl text-pink-500" />,
      iconbg: "bg-pink-100",
      change: { percent: 12, direction: "up" },
    },
    {
      title: "Space Utilizations",
      value: "200 Pallets",
      description: "lower than same time yesterday",
      icon: <LiaMoneyBillAltSolid className="text-xl text-blue-500" />,
      iconbg: "bg-blue-100",
      change: { percent: 8, direction: "up" },
    },
    {
      title: "Sustainability",
      value: "200KG CO2",
      description: "lower than same time yesterday",
      icon: <BiUser className="text-xl text-red-500" />,
      iconbg: "bg-red-100",
      change: { percent: 7, direction: "down" },
    },
  ];

  const hignImpactEventsData = [
    {
      daystogo: "2 days to go",
      value: "7%",
      description:
        "Heavy rain flood in south india is expected to impact the bound supply",
      icon: <AiOutlineDollar className="text-xl text-green-500" />,
      iconbg: "bg-green-100",
      potentialCost: "100K",
    },
    {
      daystogo: "2 days to go",
      value: "12%",
      description:
        "Heavy rain flood in south india is expected to impact the bound supply",
      icon: <MdOutlineInventory2 className="text-xl text-orange-500" />,
      iconbg: "bg-orange-100",
      potentialCost: "100K",
    },
    {
      daystogo: "2 days to go",
      value: "7%",
      description:
        "Heavy rain flood in south india is expected to impact the bound supply",
      icon: <AiOutlineSafetyCertificate className="text-xl text-yellow-500" />,
      iconbg: "bg-yellow-100",
      potentialCost: "100K",
    },
  ];

  const activityTasks = [
    {
      id: 1,
      title: "Activity 1",
      shortContent: "Brief description of Activity 1.",
      fullContent:
        "Detailed content for Activity 1. This activity encompasses marketing strategies, including social media campaigns, email marketing, and content creation to boost brand awareness and engagement. This activity involves collaborating with team members to improve project outcomes, conducting meetings to discuss progress, and setting goals for the next phases of the project.",
    },
    {
      id: 2,
      title: "Activity 2",
      shortContent: "Brief description of Activity 2.",
      fullContent:
        "This activity encompasses marketing strategies, including social media campaigns, email marketing, and content creation to boost brand awareness and engagement. This activity focuses on the analysis of data collected from various sources, generating reports to present findings, and making recommendations based on the analysis to drive strategic decisions.",
    },
  ];

  const [expandedActivityId, setExpandedActivityId] = useState(null);

  const handleViewClick = (taskId) => {
    setExpandedActivityId(taskId === expandedActivityId ? null : taskId);
  };

  return (
    <>
      <div className="border-l border-gray-300 bg-[#edebf3] h-screen flex flex-col">
        <div className="pt-5 pl-6">
          <h2 className="cus-h2-heading">Dashboard</h2>
        </div>
        <div className="flex flex-grow overflow-hidden">
          {/* dashboard cards */}
          <div className="w-3/4 py-2 pl-5 pr-1 overflow-y-auto">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
              {data.map((metric, index) => (
                <div key={index} className="p-4 rounded-lg bg-white">
                  <div className="flex items-center">
                    <div>
                      <div className="flex items-center justify-between">
                        <div>
                          <h3 className="cus-dashboard-title">
                            {metric.title}
                          </h3>
                          <p className="cus-dashboard-value">{metric.value}</p>
                        </div>
                        <div
                          className="flex items-center justify-end cursor-pointer"
                          onClick={() => navigate(`/app/${metric.path}`)}
                        >
                          <div
                            className={`flex items-center justify-center w-10 h-10 ${metric.iconbg} rounded-2xl`}
                            style={{ marginTop: "-15px" }}
                          >
                            <div>{metric.icon}</div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center mt-4">
                        {metric.change.direction === "up" ? (
                          <FaArrowTrendUp className="text-green-500" />
                        ) : (
                          <FaArrowTrendDown className="text-red-500" />
                        )}
                        <p className="ml-2 text-black">
                          <span
                            className={`${
                              metric.change.direction === "up"
                                ? "text-green-500"
                                : "text-red-500"
                            }`}
                          >
                            {metric.change.percent}%
                          </span>
                          <span className="ml-2 cus-dashboard-title">
                            {metric.description}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <h2 className="cus-h2-heading py-4">
              High Impact Events (External)
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
              {hignImpactEventsData.map((metric, index) => (
                <div key={index} className="p-4 rounded-lg bg-white">
                  <div className="flex items-center">
                    <div>
                      <div className="flex items-center justify-between">
                        <div>
                          <p className="cus-dashboard-value">{metric.value}</p>
                        </div>
                        <div>
                          <p
                            className="cus-dashboard-title flex"
                            style={{ marginTop: "-4px" }}
                          >
                            <GoDotFill className="mt-0.5 text-red-400" />{" "}
                            {metric.daystogo}
                          </p>
                          <div
                            className="flex items-center justify-end mt-0.5 cursor-pointer"
                            onClick={() => navigate(`/app/${metric.path}`)}
                          >
                            <div
                              className={`flex items-center justify-center w-10 h-10 ${metric.iconbg} rounded-2xl`}
                            >
                              <div>{metric.icon}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <p className="cus-dashboard-title">
                          {metric.description}
                        </p>
                      </div>
                      <div>
                        <h4 className="cus-dashboard-inner-title pt-2">
                          {"Potential Cost Overrun"}
                        </h4>
                        <div className="flex items-center">
                          <FaCircleChevronDown className="text-md mt-1" />
                          <p className="ml-1 cus-dashboard-value">
                            {metric.potentialCost}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Activity Track */}
          <div className="w-1/4 mt-2 mx-1 overflow-y-auto bg-white rounded-md">
            <div className="pt-4 px-2">
              <h2 className="cus-dashboard-activity-title">Activity Track</h2>{" "}
              {activityTasks.map((task) => (
                <div
                  key={task.id}
                  className="mt-4 p-4 rounded-lg bg-blue-500 text-white mb-3"
                >
                  <p
                    className={`text-white ${
                      expandedActivityId === task.id ? "" : "line-clamp-3"
                    }`}
                  >
                    {task.fullContent}
                  </p>
                  <p
                    onClick={() => handleViewClick(task.id)}
                    className="mt-2 text-white py-1 px-3 cursor-pointer border-t border-slate-100 text-center"
                  >
                    {expandedActivityId === task.id ? "Hide" : "View"}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
