import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AiOutlineIdcard } from "react-icons/ai";
import { HiOutlineIdentification } from "react-icons/hi";
import { TbFileInvoice } from "react-icons/tb";
import { FiTruck } from "react-icons/fi";
import { TfiTruck } from "react-icons/tfi";
import { Button, Switch, Table } from "antd";
import "./Visibility.css";
import MultiFieldFormComponent from "../../components/MultiFieldFormComponents";
import VisibilityDetailpage from "./VisibilityDetailpage";
import { getApi } from "../../utils/getApiMethod";

const schema = yup.object().shape({});

const Visibility = () => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      supplierid: "",
      stinumber: "",
      trucknum: "",
      carrierid: "",
      frequencyunit: "",
      stiid: "",
    },
  });
  const [visibilityFilterList, setVisibilityFilterList] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isType, setType] = useState("outbound");

  const onSubmit = async (data) => {
    const { columns, values } = Object.entries(data).reduce(
      (acc, [key, value]) => {
        if (value) {
          acc.columns.push(key);
          acc.values.push(value);
        }
        return acc;
      },
      { columns: [], values: [] }
    );

    const url = `visibility-filters?sourceid=${2}&type=${isType}${
      columns.length > 0
        ? `&column=${columns.join(",")}&value=${values.join(",")}`
        : ""
    }`;

    console.log("Constructed URL:", url);

    getVisibilityFilterList(url);
  };

  const inboundFormFields = [
    {
      name: "STINUM",
      label: "STI Number",
      type: "input",
      icon: AiOutlineIdcard,
    },
    {
      name: "ASNNUM",
      label: "ASN Number",
      type: "input",
      icon: TbFileInvoice,
    },
    {
      name: "TRUCKNUM",
      label: "Truck Number",
      type: "input",
      icon: FiTruck,
    },
    {
      name: "VENDOR",
      label: "Vendor",
      type: "input",
      icon: FiTruck,
    },
    {
      name: "STIID",
      label: "STI Id",
      type: "input",
      icon: FiTruck,
    },
  ];

  const inboundColumns = [
    {
      title: "STI Number",
      dataIndex: "STINUM",
      key: "STINUM",
    },
    {
      title: "ASN Number",
      dataIndex: "ASNNUM",
      key: "ASNNUM",
    },
    {
      title: "Truck Number",
      dataIndex: "TRUCKNUM",
      key: "TRUCKNUM",
    },
    {
      title: "Vendor",
      dataIndex: "VENDOR",
      key: "VENDOR",
    },
    {
      title: "STI Id",
      dataIndex: "STIID",
      key: "STIID",
    },
  ];

  const outboundFormFields = [
    {
      name: "ORDNUM",
      label: "Order Number",
      type: "input",
      icon: AiOutlineIdcard,
    },
    {
      name: "ORDCLASS",
      label: "Order Class",
      type: "input",
      icon: TbFileInvoice,
    },
    {
      name: "DELIVERYROUTE",
      label: "Delivery Route",
      type: "input",
      icon: FiTruck,
    },
    {
      name: "ORDTYPE",
      label: "Order Type",
      type: "input",
      icon: TfiTruck,
    },
    {
      name: "MANIFESTNUM",
      label: "Manifest Number",
      type: "input",
      icon: HiOutlineIdentification,
    },
  ];

  const outboundColumns = [
    {
      title: "Manifest Number",
      dataIndex: "MANIFESTNUM",
      key: "MANIFESTNUM",
    },
    {
      title: "Order Type",
      dataIndex: "ORDTYPE",
      key: "ORDTYPE",
    },
    {
      title: "Delivery Route",
      dataIndex: "DELIVERYROUTE",
      key: "DELIVERYROUTE",
    },
    {
      title: "Order Class",
      dataIndex: "ORDCLASS",
      key: "ORDCLASS",
    },
    {
      title: "Order Number",
      dataIndex: "ORDNUM",
      key: "ORDNUM",
    },
  ];

  const getVisibilityFilterList = async (url) => {
    try {
      const result = await getApi(url);
      if (result?.data) {
        setVisibilityFilterList(result?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleRowClick = (record) => {
    setSelectedRow(record);
  };

  const handleBackClick = () => {
    setSelectedRow(null);
    reset();
    setVisibilityFilterList([]);
  };

  const handleClear = () => {
    reset();
  };

  const num = isType === "inbound" ? selectedRow?.STINUM : selectedRow?.ORDNUM;

  const formFields =
    isType === "inbound" ? inboundFormFields : outboundFormFields;
  const columns = isType === "inbound" ? inboundColumns : outboundColumns;

  const handleTypeChange = () => {
    setType((prevType) => (prevType === "inbound" ? "outbound" : "inbound"));
    setVisibilityFilterList([]);
    reset();
  };

  return (
    <>
      <div className="flex" style={{ height: "100vh" }}>
        <div className="flex-1 flex flex-col overflow-hidden">
          {!selectedRow && (
            <div className="p-4 flex items-center justify-between border-b border-gray-300 sticky top-0 bg-white z-10">
              <h2 className="cus-h2-heading">Visibility</h2>
              <div className="flex items-center justify-end">
                <span className="mr-2">{"Outbound"}</span>
                <Switch
                  className="mr-2"
                  checked={isType === "inbound"}
                  onChange={handleTypeChange}
                />
                <span className="mr-2">{"Inbound"}</span>
              </div>
            </div>
          )}
          {selectedRow && (
            <div className="p-3 flex items-center justify-between border-b border-gray-300 sticky top-0 bg-white z-10">
              <h2 className="cus-h2-heading">Visibility</h2>
              <Button onClick={handleBackClick}>Back</Button>
            </div>
          )}

          {!selectedRow && (
            <div className="overflow-y-auto">
              <div className="p-3">
                <MultiFieldFormComponent
                  formFields={formFields}
                  handleSubmit={handleSubmit}
                  control={control}
                  errors={errors}
                  onSubmit={onSubmit}
                  handleClear={handleClear}
                />
              </div>
              <div className="mx-1">
                <Table
                  dataSource={visibilityFilterList}
                  columns={columns}
                  scroll={{ y: 150 }}
                  pagination={{ pageSize: 10 }}
                  onRow={(record) => ({
                    onClick: () => handleRowClick(record),
                  })}
                />
              </div>
            </div>
          )}
          {selectedRow && (
            <VisibilityDetailpage
              handleBackClick={handleBackClick}
              isType={isType}
              num={num}
              selectedRow={selectedRow}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Visibility;
