import React, { useEffect, useState } from "react";
import { notification } from "antd";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AiOutlineBell, AiOutlineMail, AiOutlineUser } from "react-icons/ai";
import { FaSignal, FaSlack, FaWhatsapp } from "react-icons/fa";
import { BsClockHistory, BsExclamationCircle } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { MdOutlineConfirmationNumber } from "react-icons/md";
import { getApi } from "../../../utils/getApiMethod";
import { postApi } from "../../../utils/postApiMethod";
import { deleteApi } from "../../../utils/deleteApiMethod";
import FormComponent from "../../../components/Formcomponent";

const AlertSchema = yup.object().shape({
  alarmTitle: yup.string().required("Incident Title is required"),
  operator: yup.string().required("Operator is required"),
  channels: yup.array().min(1, "At least one channel must be selected"),
  alertto: yup.array().min(1, "At least one alert option must be selected"),
  frequencyunit: yup.string().required("Frequency Unit is required"),
  severity: yup.string().required("Severity is required"),
});

const AlertConfig = ({
  selSubMetric,
  setNextButtonEnable,
  submetricMetricId,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AlertSchema),
    defaultValues: {
      alarmTitle: "",
      operator: "",
      channels: "",
      alertto: "",
      frequencyunit: "",
      severity: "",
    },
  });

  const [contactList, setContactsList] = useState([]);
  const [alertListRes, setAlertListRes] = useState([]);

  const getAlertLists = async (url) => {
    try {
      const result = await getApi(url);
      if (result?.data) {
        setAlertListRes(result?.data?.rows);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const url = `alertconfig/resource?column=metricid&value=${submetricMetricId}`;
    getAlertLists(url);
  }, [submetricMetricId]);

  const onSubmit = async (data) => {
    //  console.log("data", data);
    if (data.severity === "high") {
      data.weightage = 100;
    } else if (data.severity === "medium") {
      data.weightage = 60;
    } else if (data.severity === "low") {
      data.weightage = 30;
    }
    const undefinedValues = {};
    const modifiedData = {
      ...data,
      metricid: submetricMetricId,
      submetricname: selSubMetric.metricname || "",
    };
    Object.keys(modifiedData).forEach((key) => {
      if (modifiedData[key] === undefined) {
        undefinedValues[key] = modifiedData[key];
        delete modifiedData[key];
      }
    });
    // console.log("modifiedData", modifiedData);
    // console.log("undefinedValues", undefinedValues);
    const url = `alertconfig/resource`;
    try {
      const response = await postApi(url, modifiedData);
      if (response?.statusCode === 200) {
        notification.success({
          message: response.message,
        });
        reset();
        data.weightage = "";
        const url = `alertconfig/resource?column=metricid&value=${submetricMetricId}`;
        getAlertLists(url);
        setNextButtonEnable(false);
      }
    } catch (error) {
      console.error("Failed:", error.message);
      notification.error({
        message: error.message,
      });
      setNextButtonEnable(true);
    }
  };

  const handleRemoveAlarm = async (ele) => {
    // console.log("remove clicked...", ele);
    const url = `alertconfig/resource/${ele.id}`;
    try {
      const response = await deleteApi(url);
      if (response?.statusCode === 200) {
        notification.success({
          message: response.message,
          // description: error.message,
        });
        const url = `alertconfig/resource?column=metricid&value=${submetricMetricId}`;
        getAlertLists(url);
      }
    } catch (error) {
      console.error("Failed:", error.message);
      notification.error({
        message: error.message,
      });
    }
  };

  const formFields = [
    {
      name: "alarmTitle",
      label: "Alarm Title",
      type: "input",
      icon: AiOutlineBell,
    },
    {
      name: "operator",
      label: "Operator",
      type: "select",
      icon: FaSignal,
      options: [
        {
          value: "less_than",
          label: "Less Than",
          icon: FaSignal,
          color: "blue",
        },
        {
          value: "equal_to",
          label: "Equal To",
          icon: FaSignal,
          color: "green",
        },
        {
          value: "greater_than",
          label: "Greater Than",
          icon: FaSignal,
          color: "red",
        },
        { value: "between", label: "Between", icon: FaSignal, color: "orange" },
      ],
    },
    {
      name: "range",
      label: "Range",
      type: "input",
      conditional: "greater_than",
    },
    {
      name: "range",
      label: "Range",
      type: "input",
      conditional: "equal_to",
    },
    {
      name: "range",
      label: "Range",
      type: "input",
      conditional: "less_than",
    },
    {
      name: "startValue",
      label: "Start Value",
      type: "input",
      conditional: "between",
    },
    {
      name: "endValue",
      label: "End Value",
      type: "input",
      conditional: "between",
    },
    {
      name: "channels",
      label: "Channels",
      type: "select",
      icon: FaWhatsapp,
      options: [
        {
          value: "whatsapp",
          label: "WhatsApp",
          icon: FaWhatsapp,
          color: "green",
        },
        { value: "email", label: "Email", icon: AiOutlineMail, color: "blue" },
        { value: "slack", label: "Slack", icon: FaSlack, color: "purple" },
      ],
      multiple: true,
    },
    {
      name: "alertto",
      label: "Alert To",
      type: "select",
      icon: AiOutlineUser,
      // options: [
      //   {
      //     value: 3,
      //     label: "Priyanshu",
      //     icon: AiOutlineUser,
      //     color: "black",
      //   },
      //   { value: 1, label: "Sasi", icon: AiOutlineUser, color: "brown" },
      //   { value: 2, label: "Agila", icon: AiOutlineUser, color: "gray" },
      // ],
      options: contactList,
      multiple: true,
    },
    {
      name: "frequencyunit",
      label: "Frequency Unit",
      type: "select",
      icon: BsClockHistory,
      options: [
        { value: "day", label: "Day", icon: BsClockHistory, color: "orange" },
        { value: "hour", label: "Hour", icon: BsClockHistory, color: "red" },
      ],
    },
    {
      name: "frequency",
      label: "Frequency",
      type: "input",
      icon: MdOutlineConfirmationNumber,
    },
    {
      name: "severity",
      label: "Severity",
      type: "select",
      icon: BsExclamationCircle,
      options: [
        {
          value: "high",
          label: "High",
          icon: BsExclamationCircle,
          color: "red",
        },
        {
          value: "medium",
          label: "Medium",
          icon: BsExclamationCircle,
          color: "yellow",
        },
        {
          value: "low",
          label: "Low",
          icon: BsExclamationCircle,
          color: "green",
        },
      ],
    },
    {
      name: "weightage",
      label: "Weightage",
      type: "input",
      conditional: "severity",
    },
    // {
    //   name: "weightage",
    //   label: "Weightage",
    //   type: "input",
    //   conditional: "high",
    // },
    // {
    //   name: "weightage",
    //   label: "Weightage",
    //   type: "input",
    //   conditional: "medium",
    // },
    // {
    //   name: "weightage",
    //   label: "Weightage",
    //   type: "input",
    //   conditional: "low",
    // },
  ];

  const getContactsList = async (url) => {
    try {
      const result = await getApi(url);
      if (result?.data?.rows) {
        const options = result?.data?.rows.map((item) => ({
          value: item.id,
          label: item.name,
          icon: AiOutlineUser,
          color: "black", // You can set a color or customize it
        }));
        setContactsList(options);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const url = "contact/resource";
    getContactsList(url);
  }, []);

  return (
    <>
      <div className="flex space-x-2" style={{ height: "78vh" }}>
        <div className="flex-1 flex flex-col bg-gray-50 overflow-hidden">
          <div className="p-3 flex items-center justify-between border-b border-gray-300 sticky top-0 bg-white z-10">
            <h2 className="cus-h2-heading">
              {`Configure Alarms for a ${selSubMetric.metricname}`}
            </h2>
          </div>
          <div
            className="flex-1 space-x-6 overflow-y-auto"
            style={{ height: "100vh" }}
          >
            <div className="p-3">
              <FormComponent
                formFields={formFields}
                handleSubmit={handleSubmit}
                control={control}
                errors={errors}
                onSubmit={onSubmit}
                buttonName={"Submit"}
              />
            </div>
          </div>
        </div>

        <div className="flex-1 flex flex-col bg-gray-50 overflow-hidden border-l">
          <div className="p-3 flex items-center justify-between border-b border-gray-300 sticky top-0 bg-white z-10">
            <h2 className="cus-h2-heading">Created Alarms</h2>
          </div>
          <div className="m-2" style={{ overflowY: "auto" }}>
            {alertListRes.length > 0 ? (
              <ul>
                {alertListRes.map((ele, index) => (
                  <li
                    key={index}
                    className="p-2 m-2 border border-gray-200 rounded-lg bg-gradient-to-r from-blue-50 to-white shadow-md cursor-pointer transition-transform transform flex justify-between items-center"
                  >
                    <span className="flex-grow">{ele.message}</span>
                    <button
                      className="ml-4 text-red-600 hover:text-red-800 transition-colors p-1 rounded-full hover:bg-red-100"
                      onClick={() => handleRemoveAlarm(ele)}
                    >
                      <IoClose size={22} />
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="p-2 m-2 border border-gray-200 rounded-lg bg-gradient-to-r from-blue-50 to-white shadow-md cursor-pointer transition-transform transform hover:scale-105 hover:bg-blue-50">
                No Alarms created yet.
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AlertConfig;
