import React, { useEffect, useState } from "react";
import { Avatar, Layout, Menu } from "antd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import compLogo from "../src/assests/linkworks_logo.png";
import { MdDashboard, MdInventory2 } from "react-icons/md";
import { BsDatabaseFillAdd } from "react-icons/bs";
import { FiLogOut } from "react-icons/fi";
import { SiBaremetrics, SiVictoriametrics } from "react-icons/si";
import "./landing.css";
import { IoPersonAddSharp } from "react-icons/io5";
import { FaBolt, FaExpandArrowsAlt } from "react-icons/fa";
import { AiFillHome } from "react-icons/ai";
import { useAuth } from "./AuthContext";

const { Content, Sider } = Layout;

const items = [
  {
    key: "home",
    icon: <AiFillHome />,
    label: "Home",
  },
  {
    key: "dashboard",
    icon: <MdDashboard />,
    label: "Dashboard",
  },
  {
    key: "dbconfig",
    icon: <BsDatabaseFillAdd />,
    label: "DB Config",
  },
  {
    key: "contact",
    icon: <IoPersonAddSharp />,
    label: "Contact",
  },
  {
    key: "visibility",
    icon: <FaExpandArrowsAlt />,
    label: "Visibility",
  },
  {
    key: "inventorydetails",
    icon: <MdInventory2 />,
    label: "Inventory Details",
  },
  {
    key: "escalatedincidents",
    icon: <FaBolt />,
    label: "Escalated Incidents",
  },
  {
    key: "tickets",
    icon: <SiVictoriametrics />,
    label: "Tickets",
  },
  {
    key: "submetrics",
    icon: <SiBaremetrics />,
    label: "Sub Metrics",
    children: [
      {
        key: "cost",
        label: "Cost",
      },
      {
        key: "inventory",
        label: "Inventory",
      },
      {
        key: "safety",
        label: "Safety",
      },
    ],
  },
];

const Landing = () => {
  const navigate = useNavigate();
  // const [selectedKey, setSelectedKey] = useState("home");
  const { logout } = useAuth();
  const location = useLocation(); // Access the current location

  const [selectedKey, setSelectedKey] = useState(
    localStorage.getItem("selectedKey") || "home"
  );

  useEffect(() => {
    // Check if there is a selectedKey passed in the state
    if (location.state && location.state.selectedKey) {
      setSelectedKey(location.state.selectedKey);
    }

    localStorage.setItem("selectedKey", selectedKey);
  }, [selectedKey, location.state]);

  const handleMenuClick = ({ key }) => {
    navigate(`/app/${key}`);
    setSelectedKey(key);
  };

  const handleLogout = () => {
    console.log("Logout clicked");
    logout();
    navigate("/");
  };

  const user = {
    name: "Admin",
    initials: "A",
  };

  return (
    <Layout>
      {selectedKey !== "inventoryconfig" && (
        <Sider className="siderbar" breakpoint="lg" collapsedWidth="80">
          <div className="top-cus-logo-section">
            <img className="cus-logo" src={compLogo} alt="Profile Logo" />
          </div>
          <div>
            <Menu
              className="menu-scroll-container"
              mode={"inline"}
              items={items}
              onClick={handleMenuClick}
              selectedKeys={[selectedKey]}
            />
          </div>
          <div className="logout-section">
            <div className="flex items-center justify-between bg-white p-4">
              <Avatar className="mr-4 bg-[#247fd3]" size="small">
                <span className="cus-prof-name" style={{ color: "white" }}>
                  {user.initials}
                </span>
              </Avatar>
              <div className="flex-grow cus-prof-name">{user.name}</div>
              <FiLogOut className="ml-4 text-xl" onClick={handleLogout} />
            </div>
          </div>
        </Sider>
      )}

      <Layout>
        <Content className="content">
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default Landing;
