import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AiOutlineIdcard } from "react-icons/ai";
import { TbFileInvoice } from "react-icons/tb";
import { Button, Table } from "antd";
import "./InventoryDetails.css";
import MultiFieldFormComponent from "../../components/MultiFieldFormComponents";
import { getApi } from "../../utils/getApiMethod";
import InventoryDetailpage from "./InventoryDetailpage";

const schema = yup.object().shape({});

const InventoryDetails = () => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      INVID: "",
      SKUCODE: "",
    },
  });
  const [inventoryDetailsFilterList, setInventoryDetailsFilterList] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  const onSubmit = async (data) => {
    const { columns, values } = Object.entries(data).reduce(
      (acc, [key, value]) => {
        if (value) {
          acc.columns.push(key);
          acc.values.push(value);
        }
        return acc;
      },
      { columns: [], values: [] }
    );

    const url = `stock${
      columns.length > 0
        ? `?column=${columns.join(",")}&value=${values.join(",")}`
        : ""
    }`;

    console.log("Constructed URL:", url);

    getInventoryDetailsFilterList(url);
  };

  const formFields = [
    {
      name: "INVID",
      label: "Inventory Id",
      type: "input",
      icon: AiOutlineIdcard,
    },
    {
      name: "SKUCODE",
      label: "SKU Code",
      type: "input",
      icon: TbFileInvoice,
    },
  ];

  const tableColumns = [
    {
      title: "Inventory Id",
      dataIndex: "INVID",
      key: "INVID",
    },
    {
      title: "SKU Code",
      dataIndex: "SKUCODE",
      key: "SKUCODE",
    },
  ];

  const getInventoryDetailsFilterList = async (url) => {
    try {
      const result = await getApi(url);
      if (result?.data) {
        setInventoryDetailsFilterList(result?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleRowClick = (record) => {
    setSelectedRow(record);
  };

  const handleBackClick = () => {
    setSelectedRow(null);
    reset();
    setInventoryDetailsFilterList([]);
  };

  const handleClear = () => {
    reset();
  };

  const num = selectedRow?.INVID;

  return (
    <>
      <div className="flex" style={{ height: "100vh" }}>
        <div className="flex-1 flex flex-col overflow-hidden">
          {!selectedRow && (
            <div className="p-4 flex items-center justify-between border-b border-gray-300 sticky top-0 bg-white z-10">
              <h2 className="cus-h2-heading">Inventory</h2>
            </div>
          )}
          {selectedRow && (
            <div className="p-3 flex items-center justify-between border-b border-gray-300 sticky top-0 bg-white z-10">
              <h2 className="cus-h2-heading">Inventory</h2>
              <Button onClick={handleBackClick}>Back</Button>
            </div>
          )}

          {!selectedRow && (
            <div className="overflow-y-auto">
              <div className="p-3">
                <MultiFieldFormComponent
                  formFields={formFields}
                  handleSubmit={handleSubmit}
                  control={control}
                  errors={errors}
                  onSubmit={onSubmit}
                  buttonName={"Submit"}
                  handleClear={handleClear}
                />
              </div>
              <div className="mx-1">
                <Table
                  dataSource={inventoryDetailsFilterList}
                  columns={tableColumns}
                  scroll={{ y: 150 }}
                  pagination={{ pageSize: 10 }}
                  onRow={(record) => ({
                    onClick: () => handleRowClick(record),
                  })}
                />
              </div>
            </div>
          )}
          {selectedRow && (
            <InventoryDetailpage
              handleBackClick={handleBackClick}
              num={num}
              selectedRow={selectedRow}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default InventoryDetails;
