import { Button, Timeline } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./InventoryConfig.css";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { AiOutlineHome } from "react-icons/ai";
import DatabaseConfig from "./DatabaseConfig";
import {
  AiOutlineDatabase,
  AiOutlineFolderOpen,
  AiOutlineGlobal,
} from "react-icons/ai";
import { MdPortableWifiOff } from "react-icons/md";
import { BsFileEarmarkText, BsFillLockFill } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import {
  SiMongodb,
  SiSqlite,
  SiOracle,
  SiMicrosoftsqlserver,
  SiFirebase,
  SiMysql,
  SiPostgresql,
} from "react-icons/si";
import { FaFileExport } from "react-icons/fa";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { postApi } from "../../../utils/postApiMethod";
import { useForm } from "react-hook-form";
import { notification } from "antd";
import AlertConfig from "./AlertConfig";
import EscalationConfig from "./EscalationConfig";
import SubMetricsConfig from "./SubMetricsConfig";
import { getApi } from "../../../utils/getApiMethod";

const InventoryConfig = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const metricData = location.state?.metricData;

  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const steps = [
    { title: "Database Configuration" },
    { title: "Submetric Configuration" },
    { title: "Alarm Configuration" },
    { title: "Escalation Configuration" },
  ];

  const handleCancelClick = () => {
    navigate("/app/inventory", { state: { selectedKey: "inventory" } });
  };

  const handleNextClick = () => {
    console.log("currentStepIndex", currentStepIndex);
    if (currentStepIndex === steps.length - 1) {
      navigate("/app/inventory", { state: { selectedKey: "inventory" } });
    } else {
      setCurrentStepIndex(currentStepIndex + 1);
      setNextButtonEnable(true);
    }
  };

  const handlePreviousClick = () => {
    if (currentStepIndex > 0) {
      if (currentStepIndex === 1) {
        setSelectedItem("");
        setNextButtonEnable(true);
      }
      setCurrentStepIndex(currentStepIndex - 1);
    }
  };

  //------------------------------
  const [selectedDB, setSelectedDB] = useState(null);
  const [testBtnLoading, setTestBtnLoading] = useState(false);
  const [nextButtonEnable, setNextButtonEnable] = useState(true);
  const [configuredDBSourceId, setConfiguredDBSourceId] = useState(0);
  const [submetricMetricId, setSubmetricMetricId] = useState("");

  const databases = [
    { name: "FTP", icon: <FaFileExport />, color: "#FF9800" },
    { name: "MySQL", icon: <SiMysql />, color: "#00758F" },
    { name: "PostgreSQL", icon: <SiPostgresql />, color: "#336791" },
    { name: "MongoDB", icon: <SiMongodb />, color: "#47A248" },
    { name: "SQLite", icon: <SiSqlite />, color: "#003B57" },
    { name: "Oracle", icon: <SiOracle />, color: "#F80000" },
    {
      name: "Microsoft SQL Server",
      icon: <SiMicrosoftsqlserver />,
      color: "#CC2927",
    },
    { name: "Firebase", icon: <SiFirebase />, color: "#FFCA28" },
  ];

  const schema = yup.object().shape(
    selectedDB === "FTP"
      ? {
          ftpHost: yup.string().required("Host Name is required"),
          ftpPort: yup.string().required("Port is required"),
          ftpUsername: yup.string().required("Username is required"),
          ftpPassword: yup.string().required("Password is required"),
          fileType: yup.string().required("Filetype is required"),
          filePath: yup.string().required("Filepath is required"),
        }
      : {
          dataSourceName: yup.string().required("Datasource Name is required"),
          hostAddress: yup.string().required("Host Address is required"),
          port: yup
            .number()
            .typeError("Port must be a number")
            .required("Port is required"),
          databaseName: yup.string().required("Database Name is required"),
          username: yup.string().required("Username is required"),
          password: yup.string().required("Password is required"),
          useSSH: yup.boolean(),
          enableSSHTunneling: yup.boolean(),
        }
  );

  const defaultValues =
    selectedDB === "FTP"
      ? {
          ftpHost: "",
          ftpPort: "",
          ftpUsername: "",
          ftpPassword: "",
          fileType: "",
          filePath: "",
        }
      : {
          dataSourceName: "",
          hostAddress: "",
          port: "",
          databaseName: "",
          username: "",
          password: "",
          useSSH: true,
          enableSSHTunneling: false,
        };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const dbFormFields = [
    {
      name: "dataSourceName",
      label: "Datasource Name",
      type: "input",
      icon: AiOutlineDatabase,
    },
    {
      name: "hostAddress",
      label: "Host Address",
      type: "input",
      icon: AiOutlineGlobal,
    },
    {
      name: "port",
      label: "Port",
      type: "input",
      icon: MdPortableWifiOff,
    },
    {
      name: "databaseName",
      label: "Database Name",
      type: "input",
      icon: AiOutlineDatabase,
    },
    {
      name: "username",
      label: "Username",
      type: "input",
      icon: FaUser,
    },
    {
      name: "password",
      label: "Password",
      type: "input",
      icon: BsFillLockFill,
      password: true,
    },
    {
      name: "useSSH",
      label: "Use SSH",
      type: "switch",
      // icon: GiSwitchblade,
    },
    {
      name: "enableSSHTunneling",
      label: "Enable SSH Tunneling",
      type: "switch",
      // icon: GiSwitchWeapon,
    },
    {
      name: "For LinkworksAI to access your database you likely need to allowlist our IP address in your database.",
      type: "para",
    },
    {
      name: "ipaddress",
      label: "LinkworksAI's IP Address",
      type: "input",
      icon: AiOutlineGlobal,
      password: true,
    },
  ];

  const ftpFormFields = [
    {
      name: "ftpHost",
      label: "FTP Host",
      type: "input",
      icon: AiOutlineGlobal,
    },
    {
      name: "ftpPort",
      label: "FTP Port",
      type: "input",
      icon: MdPortableWifiOff,
    },
    {
      name: "ftpUsername",
      label: "FTP Username",
      type: "input",
      icon: FaUser,
    },
    {
      name: "ftpPassword",
      label: "FTP Password",
      type: "input",
      icon: BsFillLockFill,
      password: true,
    },
    {
      name: "fileType",
      label: "File Type",
      type: "input",
      icon: BsFileEarmarkText,
    },
    {
      name: "filePath",
      label: "File Path",
      type: "input",
      icon: AiOutlineFolderOpen,
    },
  ];

  const selectedFormFields =
    selectedDB === "FTP" ? ftpFormFields : dbFormFields;

  const handleAddClick = (dbName) => {
    reset();
    // console.log("Selected DB:", dbName);
    setSelectedDB(dbName);
    setNextButtonEnable(true);
    setSelectedItem("");
  };

  const onSubmitFTP = async (data) => {
    // console.log("clicked");
    setTestBtnLoading(true);
    // console.log("Connection Data:", data);
    const payload = {
      host: data.ftpHost,
      port: data.ftpPort,
      username: data.ftpUsername,
      password: data.ftpPassword,
      filetype: data.fileType,
      filepath: data.filePath,
    };
    const url = "ftpconfig/resource";
    try {
      const response = await postApi(url, payload);
      if (response?.statusCode === 200) {
        notification.success({
          message: response.message,
        });
        reset();
        setNextButtonEnable(false);
        console.log("data.sourceid", response?.data.sourceid);
        setConfiguredDBSourceId(response?.data.sourceid);
      }
    } catch (error) {
      console.error("SignIn failed:", error.message);
      notification.error({
        message: error.message,
      });
      setNextButtonEnable(true);
    } finally {
      setTestBtnLoading(false);
    }
  };

  const onSubmitDB = async (data) => {
    // console.log("clicked");
    setTestBtnLoading(true);
    // console.log("Connection Data:", data);
    const payload = {
      dbtype: data.dataSourceName,
      host: data.hostAddress,
      port: data.port,
      dbname: data.databaseName,
      username: data.username,
      password: data.password,
      source: "",
    };

    const url = "dbconfig";
    try {
      const response = await postApi(url, payload);
      if (response?.statusCode === 200) {
        notification.success({
          message: response.message,
        });
        reset();
        setNextButtonEnable(false);
        console.log("data.sourceid", response?.data.sourceid);
        setConfiguredDBSourceId(response?.data.sourceid);
      }
    } catch (error) {
      console.error("SignIn failed:", error.message);
      notification.error({
        message: error.message,
      });
      setNextButtonEnable(true);
    } finally {
      setTestBtnLoading(false);
    }
  };

  const [connectedDatabases, setConnectedDatabases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [connectedFTPs, setConnectedFTPs] = useState([]);

  const getDBConfig = async (url) => {
    setLoading(true);
    try {
      // const url = "dbconfig/resource";
      const result = await getApi(url);
      if (result?.data?.rows) {
        setConnectedDatabases(result?.data?.rows);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const url = "dbconfig/resource";
    getDBConfig(url);
  }, []);

  const getFTPConfig = async (url) => {
    setLoading(true);
    try {
      // const url = "dbconfig/resource";
      const result = await getApi(url);
      if (result?.data?.rows) {
        setConnectedFTPs(result?.data?.rows);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const url = "ftpconfig/resource";
    getFTPConfig(url);
  }, []);

  const [selectedItem, setSelectedItem] = useState("");

  const handleSelect = (item) => {
    setConfiguredDBSourceId(item.sourceid);
    setSelectedItem(item.id);
    setNextButtonEnable(false);
    // console.log("Selected item data:", item);
  };

  return (
    <>
      <div className="h-screen">
        {/* Top Bar */}
        <div className="flex items-center pl-4 border-b bg-white h-[8vh]">
          <h2 className="cus-h2-heading">Configuration</h2>
        </div>

        {/* Main Content */}
        <div className="flex overflow-hidden bg-[#f0f6f7] h-[92vh]">
          {/* Timeline Container */}
          <div className="bg-white w-[16%] p-3">
            <Timeline>
              {steps.map((step, index) => (
                <Timeline.Item
                  key={index}
                  style={{ cursor: "pointer" }}
                  color={index <= currentStepIndex ? "blue" : "grey"}
                >
                  <span
                    className={`timeline-heading ${
                      index <= currentStepIndex
                        ? "text-blue-500"
                        : "text-gray-500"
                    }`}
                  >
                    {step.title}
                  </span>
                </Timeline.Item>
              ))}
            </Timeline>
          </div>

          {/* Configuration Container */}
          <div className="p-2 flex flex-1">
            <div className="flex-1 bg-white flex flex-col h-full">
              <div className="flex flex-col flex-1 overflow-y-auto">
                <div className="flex-grow">
                  {currentStepIndex === 0 && (
                    <DatabaseConfig
                      selectedFormFields={selectedFormFields}
                      selectedDB={selectedDB}
                      setSelectedDB={setSelectedDB}
                      databases={databases}
                      control={control}
                      errors={errors}
                      handleSubmit={handleSubmit}
                      reset={reset}
                      testBtnLoading={testBtnLoading}
                      handleAddClick={handleAddClick}
                      onSubmitFTP={onSubmitFTP}
                      onSubmitDB={onSubmitDB}
                      loading={loading}
                      connectedDatabases={connectedDatabases}
                      connectedFTPs={connectedFTPs}
                      handleSelect={handleSelect}
                      selectedItem={selectedItem}
                    />
                  )}
                  {currentStepIndex === 1 && (
                    <SubMetricsConfig
                      selSubMetric={metricData}
                      configuredDBSourceId={configuredDBSourceId}
                      setNextButtonEnable={setNextButtonEnable}
                      setSubmetricMetricId={setSubmetricMetricId}
                    />
                  )}

                  {currentStepIndex === 2 && (
                    <div>
                      <AlertConfig
                        selSubMetric={metricData}
                        setNextButtonEnable={setNextButtonEnable}
                        submetricMetricId={submetricMetricId}
                      />
                    </div>
                  )}

                  {currentStepIndex === 3 && (
                    <div>
                      <EscalationConfig
                        selectedMetricForEscalation={metricData}
                        setNextButtonEnable={setNextButtonEnable}
                        submetricMetricId={submetricMetricId}
                      />
                    </div>
                  )}
                </div>
                {/* Bottom Button Group */}
                <div className="p-4 flex justify-between items-center border-t">
                  <Button icon={<AiOutlineHome />} onClick={handleCancelClick}>
                    Cancel
                  </Button>
                  <div className="flex space-x-2">
                    <Button
                      icon={<ArrowLeftOutlined />}
                      onClick={handlePreviousClick}
                      disabled={currentStepIndex === 0}
                    >
                      Previous Step
                    </Button>
                    <Button
                      type="primary"
                      icon={<ArrowRightOutlined />}
                      onClick={handleNextClick}
                      disabled={nextButtonEnable}
                    >
                      {currentStepIndex === steps.length - 1
                        ? "Finish"
                        : "Next"}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InventoryConfig;
