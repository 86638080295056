import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, notification, Spin } from "antd";
import {
  SiMongodb,
  SiSqlite,
  SiOracle,
  SiMicrosoftsqlserver,
  SiFirebase,
  SiMysql,
  SiPostgresql,
} from "react-icons/si";
import { postApi } from "../../utils/postApiMethod";
import { getApi } from "../../utils/getApiMethod";
import {
  AiOutlineDatabase,
  AiOutlineFolderOpen,
  AiOutlineGlobal,
} from "react-icons/ai";
import { MdPortableWifiOff } from "react-icons/md";
import { FaFileExport, FaUser } from "react-icons/fa";
import { BsFileEarmarkText, BsFillLockFill } from "react-icons/bs";
import FormComponent from "../../components/Formcomponent";

const databases = [
  { name: "FTP", icon: <FaFileExport />, color: "#FF9800" },
  { name: "MySQL", icon: <SiMysql />, color: "#00758F" },
  { name: "PostgreSQL", icon: <SiPostgresql />, color: "#336791" },
  { name: "MongoDB", icon: <SiMongodb />, color: "#47A248" },
  { name: "SQLite", icon: <SiSqlite />, color: "#003B57" },
  { name: "Oracle", icon: <SiOracle />, color: "#F80000" },
  {
    name: "Microsoft SQL Server",
    icon: <SiMicrosoftsqlserver />,
    color: "#CC2927",
  },
  { name: "Firebase", icon: <SiFirebase />, color: "#FFCA28" },
];

const DBConfig = () => {
  const [selectedDB, setSelectedDB] = useState(null);
  const [connectedDatabases, setConnectedDatabases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [testBtnLoading, setTestBtnLoading] = useState(false);
  const [connectedFTPs, setConnectedFTPs] = useState([]);

  const schema = yup.object().shape(
    selectedDB === "FTP"
      ? {
          ftpHost: yup.string().required("Host Name is required"),
          ftpPort: yup.string().required("Port is required"),
          ftpUsername: yup.string().required("Username is required"),
          ftpPassword: yup.string().required("Password is required"),
          fileType: yup.string().required("Filetype is required"),
          filePath: yup.string().required("Filepath is required"),
        }
      : {
          dataSourceName: yup.string().required("Datasource Name is required"),
          hostAddress: yup.string().required("Host Address is required"),
          port: yup
            .number()
            .typeError("Port must be a number")
            .required("Port is required"),
          databaseName: yup.string().required("Database Name is required"),
          username: yup.string().required("Username is required"),
          password: yup.string().required("Password is required"),
          useSSH: yup.boolean(),
          enableSSHTunneling: yup.boolean(),
        }
  );

  const defaultValues =
    selectedDB === "FTP"
      ? {
          ftpHost: "",
          ftpPort: "",
          ftpUsername: "",
          ftpPassword: "",
          fileType: "",
          filePath: "",
        }
      : {
          dataSourceName: "",
          hostAddress: "",
          port: "",
          databaseName: "",
          username: "",
          password: "",
          useSSH: true,
          enableSSHTunneling: false,
        };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const handleAddClick = (dbName) => {
    reset();
    console.log("Selected DB:", dbName);
    setSelectedDB(dbName);
  };

  const onSubmitFTP = async (data) => {
    console.log("clicked");
    setTestBtnLoading(true);
    console.log("Connection Data:", data);
    const payload = {
      host: data.ftpHost,
      port: data.ftpPort,
      username: data.ftpUsername,
      password: data.ftpPassword,
      filetype: data.fileType,
      filepath: data.filePath,
    };
    const url = "ftpconfig/resource";
    try {
      const response = await postApi(url, payload);
      if (response?.statusCode === 200) {
        notification.success({
          message: response.message,
          // description: error.message,
        });
        const urlFtp = "ftpconfig/resource";
        getFTPConfig(urlFtp);
        setSelectedDB(null);
      }
    } catch (error) {
      console.error("SignIn failed:", error.message);
      notification.error({
        message: error.message,
      });
    } finally {
      setTestBtnLoading(false);
    }
  };

  const onSubmitDB = async (data) => {
    console.log("clicked");
    setTestBtnLoading(true);
    console.log("Connection Data:", data);
    const payload = {
      dbtype: data.dataSourceName,
      host: data.hostAddress,
      port: data.port,
      dbname: data.databaseName,
      username: data.username,
      password: data.password,
      source: "",
      // client: "117_test",
      // useSSH: data.useSSH,
      // enableSSHTunneling: data.enableSSHTunneling,
    };

    const url = "dbconfig";
    try {
      const response = await postApi(url, payload);
      if (response?.statusCode === 200) {
        notification.success({
          message: response.message,
          // description: error.message,
        });
        const url = "dbconfig/resource";
        getDBConfig(url);
        setSelectedDB(null);
      }
    } catch (error) {
      console.error("SignIn failed:", error.message);
      notification.error({
        message: error.message,
      });
    } finally {
      setTestBtnLoading(false);
    }
  };

  const getDBConfig = async (url) => {
    setLoading(true);
    try {
      // const url = "dbconfig/resource";
      const result = await getApi(url);
      if (result?.data?.rows) {
        setConnectedDatabases(result?.data?.rows);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const url = "dbconfig/resource";
    getDBConfig(url);
  }, []);

  const getFTPConfig = async (url) => {
    setLoading(true);
    try {
      // const url = "dbconfig/resource";
      const result = await getApi(url);
      if (result?.data?.rows) {
        setConnectedFTPs(result?.data?.rows);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const url = "ftpconfig/resource";
    getFTPConfig(url);
  }, []);

  const selectedDatabase = databases.find((db) => db.name === selectedDB);

  const dbFormFields = [
    {
      name: "dataSourceName",
      label: "Datasource Name",
      type: "input",
      icon: AiOutlineDatabase,
    },
    {
      name: "hostAddress",
      label: "Host Address",
      type: "input",
      icon: AiOutlineGlobal,
    },
    {
      name: "port",
      label: "Port",
      type: "input",
      icon: MdPortableWifiOff,
    },
    {
      name: "databaseName",
      label: "Database Name",
      type: "input",
      icon: AiOutlineDatabase,
    },
    {
      name: "username",
      label: "Username",
      type: "input",
      icon: FaUser,
    },
    {
      name: "password",
      label: "Password",
      type: "input",
      icon: BsFillLockFill,
      password: true,
    },
    {
      name: "useSSH",
      label: "Use SSH",
      type: "switch",
      // icon: GiSwitchblade,
    },
    {
      name: "enableSSHTunneling",
      label: "Enable SSH Tunneling",
      type: "switch",
      // icon: GiSwitchWeapon,
    },
    {
      name: "For LinkworksAI to access your database you likely need to allowlist our IP address in your database.",
      type: "para",
    },
    {
      name: "ipaddress",
      label: "LinkworksAI's IP Address",
      type: "input",
      icon: AiOutlineGlobal,
      password: true,
    },
  ];

  const ftpFormFields = [
    {
      name: "ftpHost",
      label: "FTP Host",
      type: "input",
      icon: AiOutlineGlobal,
    },
    {
      name: "ftpPort",
      label: "FTP Port",
      type: "input",
      icon: MdPortableWifiOff,
    },
    {
      name: "ftpUsername",
      label: "FTP Username",
      type: "input",
      icon: FaUser,
    },
    {
      name: "ftpPassword",
      label: "FTP Password",
      type: "input",
      icon: BsFillLockFill,
      password: true,
    },
    {
      name: "fileType",
      label: "File Type",
      type: "input",
      icon: BsFileEarmarkText,
    },
    {
      name: "filePath",
      label: "File Path",
      type: "input",
      icon: AiOutlineFolderOpen,
    },
  ];

  const selectedFormFields =
    selectedDB === "FTP" ? ftpFormFields : dbFormFields;

  return (
    <div>
      {selectedDB ? (
        <div className="flex space-x-6" style={{ height: "100vh" }}>
          <div className="flex-1">
            <div className="p-3 flex items-center justify-between border-b border-gray-300 sticky top-0 bg-white z-10">
              <h2 className="cus-h2-heading">
                Configure your {selectedDB} account
              </h2>
              <Button
                onClick={() => {
                  setSelectedDB(null);
                  reset();
                }}
              >
                Discard
              </Button>
            </div>
            <div className="flex mt-2 p-2">
              <div
                className={`flex items-center justify-center w-10 h-10 bg-gray-50 rounded-full`}
              >
                <div>
                  {selectedDatabase?.icon &&
                    React.cloneElement(selectedDatabase.icon, {
                      className: "text-xl",
                      style: { color: selectedDatabase.color },
                    })}
                </div>
              </div>
              <div className="ml-2">
                <p className="com-heading">
                  {`Configure ${selectedDB} for your account`}
                </p>
                <p className="agg-value mt-1">{`${selectedDB} Database`}</p>
              </div>
            </div>
            <div className="p-3 overflow-y-auto">
              <FormComponent
                formFields={selectedFormFields}
                handleSubmit={handleSubmit}
                control={control}
                errors={errors}
                onSubmit={selectedDB !== "FTP" ? onSubmitDB : onSubmitFTP}
                buttonName={
                  selectedDB !== "FTP"
                    ? "Test and Create DBConnection"
                    : "Test and Create FTPConnection"
                }
                buttonLoading={testBtnLoading}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex p-2" style={{ height: "100vh" }}>
          <div className="flex-1 flex flex-col border border-gray-300 mr-1 rounded-md overflow-hidden">
            <div className="p-3 flex items-center justify-between border-b border-gray-300 sticky top-0 bg-white z-10">
              <h2 className="cus-h2-heading">
                Most Used Integration Databases
              </h2>
            </div>
            <div className="flex-1 overflow-y-auto">
              <ul className="mt-2">
                {databases.map((db) => (
                  <li
                    key={db.name}
                    className="flex items-center justify-between p-4 mb-4 mx-2 bg-white rounded-lg shadow-sm border border-gray-200"
                  >
                    <div className="flex items-center space-x-3">
                      <span className="text-3xl" style={{ color: db.color }}>
                        {db.icon}
                      </span>
                      <span className="db-list">{db.name}</span>
                    </div>
                    <Button onClick={() => handleAddClick(db.name)}>Add</Button>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="flex-1 flex flex-col border border-gray-300 ml-1 rounded-md overflow-hidden">
            <div className="p-3 flex items-center justify-between border-b border-gray-300 sticky top-0 bg-white z-10">
              <h2 className="cus-h2-heading">Connected Database</h2>
            </div>
            <div className="flex-1 overflow-y-auto">
              {loading ? (
                <div className="flex justify-center items-center h-full">
                  <Spin size="large" />
                </div>
              ) : (
                <ul className="mt-2">
                  {/* {connectedDatabases.map((db, index) => ( */}
                  {[...connectedDatabases, ...connectedFTPs].map(
                    (item, index) => (
                      <li
                        key={index}
                        className="p-4 mb-4 mx-2 bg-white rounded-lg shadow-sm border border-gray-200"
                      >
                        <div className="mb-2">
                          <span
                            className="com-heading"
                            style={{ color: "#1E90FF" }}
                          >
                            Host:
                          </span>{" "}
                          <span className="com-value">{item.host}</span>
                        </div>
                        <div className="mb-2">
                          <span
                            className="com-heading"
                            style={{ color: "#10B981" }}
                          >
                            Database/FTP Type:
                          </span>{" "}
                          <span className="com-value">
                            {item.dbtype || item.filetype}
                          </span>
                        </div>
                        <div>
                          <span
                            className="com-heading"
                            style={{ color: "#A855F7" }}
                          >
                            Database/FTP Name:
                          </span>{" "}
                          <span className="com-value">
                            {item.dbname || item.filename}
                          </span>
                        </div>
                      </li>
                    )
                  )}
                </ul>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DBConfig;
