import React from "react";
import { Carousel, Table } from "antd";
import { HiLightBulb } from "react-icons/hi";
import { FaCheckCircle, FaTimesCircle, FaTasks } from "react-icons/fa";
import "./Homepage.css";
import { IoMdBriefcase } from "react-icons/io";
import { FaRegHourglassHalf } from "react-icons/fa6";

const HomePage = () => {
  const columns = [
    { title: "Metric", dataIndex: "metric", key: "metric" },
    {
      title: "Escalation Date",
      dataIndex: "escalationDate",
      key: "escalationDate",
    },
    { title: "Status", dataIndex: "status", key: "status" },
  ];

  const escalatedAccidents = [
    {
      key: "1",
      metric: "OTIF",
      escalationDate: "2024-09-23",
      status: <HiLightBulb className="text-xl text-green-600" />,
    },
    {
      key: "2",
      metric: "Ageing Stock",
      escalationDate: "2024-09-22",
      status: <HiLightBulb className="text-xl text-red-600" />,
    },
    {
      key: "3",
      metric: "Space Utilization",
      escalationDate: "2024-09-20",
      status: <HiLightBulb className="text-xl text-red-600" />,
    },
  ];

  const quickSummaryData = {
    title: "Quick Summary",
    items: [
      {
        id: 1,
        task: "Employee attendance % was High at 92%",
        done: true,
      },
      {
        id: 2,
        task: "100% of all inbound were putaway completed",
        done: true,
      },
      {
        id: 3,
        task: "Warehouse picked 96% of on time and in full",
        done: false,
      },
      {
        id: 4,
        task: "Sreejith was the best picker with 140 orders",
        done: true,
      },
      {
        id: 5,
        task: "8 orders were not picked due to nil picks",
        done: true,
      },
    ],
  };

  const summaryPlanData = {
    title: "Summary PlanData",
    items: [
      {
        id: 1,
        plan: "20 trucks from suppliers are scheduled",
        workstarted: true,
      },
      {
        id: 2,
        plan: "There are 848 orders to be picked today",
        workstarted: true,
      },
      {
        id: 3,
        plan: "ageing stock increased at 3% to be cleared",
        workstarted: false,
      },
      {
        id: 4,
        plan: "cycle count for 80 locations to be completed",
        workstarted: false,
      },
    ],
  };

  const carouselSettings = {
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };

  return (
    <div style={{ height: "100vh" }}>
      <div className="flex p-2" style={{ height: "50vh" }}>
        <div className="flex-1 flex flex-col border border-gray-100 mr-1 bg-white rounded-lg shadow-md overflow-hidden">
          <p className="cus-h2-heading flex items-center justify-center border-b p-2">
            <FaTasks className="mr-2 text-blue-500" />
            {quickSummaryData.title}
          </p>

          <Carousel {...carouselSettings}>
            {quickSummaryData.items.map((item) => (
              <div
                key={item.id}
                className="flex flex-col items-center justify-center text-center h-[39vh] p-4"
              >
                <p className="cus-h2-heading flex items-center justify-center">
                  {item.task}
                </p>

                <p
                  className={`home-status flex items-center justify-center mt-2 ${
                    item.done ? "text-green-500" : "text-red-500"
                  }`}
                >
                  {item.done ? (
                    <FaCheckCircle className="mr-2" />
                  ) : (
                    <FaTimesCircle className="mr-2" />
                  )}
                  {item.done ? "Completed" : "Pending"}
                </p>
              </div>
            ))}
          </Carousel>
        </div>
        <div className="flex-1 flex flex-col border border-gray-100 mr-1 bg-white rounded-lg shadow-md overflow-hidden">
          <p className="cus-h2-heading flex items-center justify-center border-b p-2">
            <FaTasks className="mr-2 text-blue-500" />
            {summaryPlanData.title}
          </p>

          <Carousel {...carouselSettings}>
            {summaryPlanData.items.map((item) => (
              <div
                key={item.id}
                className="flex flex-col items-center justify-center text-center h-[39vh] p-4"
              >
                <p className="cus-h2-heading flex items-center justify-center">
                  {item.plan}
                </p>

                <p
                  className={`home-status flex items-center justify-center mt-2 ${
                    item.workstarted ? "text-green-500" : "text-red-500"
                  }`}
                >
                  {item.workstarted ? (
                    <FaRegHourglassHalf className="mr-2" />
                  ) : (
                    <IoMdBriefcase className="mr-2" />
                  )}
                  {item.workstarted ? "Work In-progress" : "Yet to Start"}
                </p>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
      <div className="flex p-2" style={{ height: "50vh" }}>
        <div className="w-full">
          <h3 className="cus-home-title ml-2">Escalated Accidents</h3>
          <Table
            className="mt-2"
            columns={columns}
            dataSource={escalatedAccidents}
            pagination={false}
          />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
