import { Table } from "antd";
import React, { useEffect, useState } from "react";
import "./EscalatedIncident.css";
import { getApi } from "../../utils/getApiMethod";

const EscalatedIncident = () => {
  const [escalationList, setEscalationList] = useState([]);

  const columns = [
    {
      title: "Escalation Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Submetric Name",
      dataIndex: "submetric",
      key: "submetric",
    },
    {
      title: "Level Of Escalation",
      dataIndex: "level",
      key: "level",
    },
    {
      title: "Assigne",
      dataIndex: "assignto",
      key: "assignto",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  const getEscalationList = async (url) => {
    try {
      const result = await getApi(url);
      if (result?.data?.rows) {
        setEscalationList(result?.data?.rows);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const url =
      "dynamic-search?PrimaryTableName=TV_ESCAL00&attributes=date,submetric,assignto,level,status";
    getEscalationList(url);
  }, []);

  return (
    <>
      <div className="flex" style={{ height: "100vh" }}>
        <div className="flex-1 flex flex-col border overflow-hidden">
          <div className="p-4 flex items-center justify-between border-b border-gray-300 sticky top-0 bg-white z-10">
            <h2 className="cus-h2-heading">Escalated Incident</h2>
          </div>
          <Table
            dataSource={escalationList}
            //   dataSource={[]}
            columns={columns}
            scroll={{ y: 400 }}
            pagination={{ pageSize: 10 }}
            //   onRow={(record) => ({
            //     onClick: () => handleNameClick(record),
            //   })}
          />
        </div>
      </div>
    </>
  );
};

export default EscalatedIncident;
