import React, { useEffect, useState } from "react";
import SubMetrics from "../SubMetrics";
import { IoClose } from "react-icons/io5";
import { getApi } from "../../../utils/getApiMethod";
import { notification } from "antd";
import { deleteApi } from "../../../utils/deleteApiMethod";
import { FiPlusCircle } from "react-icons/fi";
import AddNewSubmetricModal from "./AddNewSubmetricModal";
import { postApi } from "../../../utils/postApiMethod";
import { putApi } from "../../../utils/putApiMethod";
import ConfigureEscalation from "./ConfigureEscalation";
import { useNavigate } from "react-router-dom";

const Inventory = () => {
  const navigate = useNavigate();
  const [metrics, setMetrics] = useState([]);
  const [droppedMetrics, setDroppedMetrics] = useState([]);
  const [selectedMetric, setSelectedMetric] = useState(null);
  const [configureStarted, setConfigureStarted] = useState(false);
  const submetricSelectedType = "Inventory";
  const [isShowEscalationList, setIsShowEscalationList] = useState(false);

  const getInventorySubMetricLists = async (url) => {
    try {
      const result = await getApi(url);
      if (result?.data) {
        // setMetrics(result?.data);
        const filteredMetrics = result?.data.filter(
          (metric) => metric.SID === null
        );
        const filteredDroppedMetrics = result?.data.filter(
          (metric) => metric.SID !== null
        );

        const droppedMetricsWithWeightage = filteredDroppedMetrics.map(
          (metric) => {
            const Weightage = (100 / filteredDroppedMetrics.length).toFixed(2);
            return { ...metric, Weightage };
          }
        );
        // console.log("droppedMetricsWithWeightage", droppedMetricsWithWeightage);
        // Log metricname and Weightage
        const metricsArray = droppedMetricsWithWeightage.map((metric) => ({
          metricname: metric.metricname,
          weightage: metric.Weightage,
          metric_type: submetricSelectedType,
          id: metric.metricid,
        }));
        // console.log("Metrics and their Weightage:", metricsArray);
        updateWeightage(metricsArray);

        setMetrics(filteredMetrics);
        setDroppedMetrics(droppedMetricsWithWeightage);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const url = `getAllSubMetric?metric=${submetricSelectedType}`;
    getInventorySubMetricLists(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDragStart = (e, metric) => {
    e.dataTransfer.setData("metric", JSON.stringify(metric));
  };

  const onDrop = (e) => {
    const metric = JSON.parse(e.dataTransfer.getData("metric"));
    if (configureStarted) {
      notification.warning({
        message:
          "Please configure the existing metric before adding a new one.",
      });
      return;
    }
    if (metrics.find((item) => item.metricname === metric.metricname)) {
      // setDroppedMetrics([...droppedMetrics, metric]);
      // setMetrics(
      //   metrics.filter((item) => item.metricname !== metric.metricname)
      // );
      // setConfigureStarted(true);

      // with weightage
      const updatedDroppedMetrics = [...droppedMetrics, metric];
      const newWeightage = (100 / updatedDroppedMetrics.length).toFixed(2);
      const droppedMetricsWithWeightage = updatedDroppedMetrics.map((m) => ({
        ...m,
        Weightage: newWeightage,
      }));

      setDroppedMetrics(droppedMetricsWithWeightage);
      setMetrics(
        metrics.filter((item) => item.metricname !== metric.metricname)
      );
      setConfigureStarted(true);
    }
  };

  const allowDrop = (e) => {
    e.preventDefault();
  };

  const handleCardClick = (metric) => {
    console.log("metric", metric);
    navigate("/app/inventoryconfig", {
      state: { metricData: metric, selectedKey: "inventoryconfig" },
    });
  };

  const handleBackClick = async () => {
    setSelectedMetric(null);
    setConfigureStarted(false);
    const url = `getAllSubMetric?metric=${submetricSelectedType}`;
    getInventorySubMetricLists(url);
  };

  const handleRemove = async (metric) => {
    // console.log("remove clicked...", metric.SID);

    // if (metric.SID === null) {
    //   setDroppedMetrics(
    //     droppedMetrics.filter((item) => item.metricname !== metric.metricname)
    //   );
    //   setMetrics([...metrics, metric]);
    //   setConfigureStarted(false);
    //   return;
    // }

    // with weightage
    if (metric.SID === null) {
      const updatedDroppedMetrics = droppedMetrics.filter(
        (item) => item.metricname !== metric.metricname
      );

      const newWeightage =
        updatedDroppedMetrics.length > 0
          ? (100 / updatedDroppedMetrics.length).toFixed(2)
          : 0;
      const droppedMetricsWithWeightage = updatedDroppedMetrics.map((m) => ({
        ...m,
        Weightage: newWeightage,
      }));

      setDroppedMetrics(droppedMetricsWithWeightage);
      setMetrics([...metrics, metric]);
      setConfigureStarted(false);
      return;
    }

    const url = `delete-metric?SID=${metric.SID}`;
    try {
      const response = await deleteApi(url);
      if (response?.statusCode === 200) {
        notification.success({
          message: response.message,
          // description: error.message,
        });
        const url = `getAllSubMetric?metric=${submetricSelectedType}`;
        getInventorySubMetricLists(url);
      }
    } catch (error) {
      console.error("Failed:", error.message);
      notification.error({
        message: error.message,
      });
    }
  };

  const updateWeightage = async (metricWeightageList) => {
    // console.log("metricWeightageList", metricWeightageList);
    const url = `update-weightage`;
    try {
      const response = await putApi(url, metricWeightageList);
      if (response?.statusCode === 200) {
        console.log("updated weightage", response.message);
      }
    } catch (error) {
      console.error("SignIn failed:", error.message);
      notification.error({
        message: error.message,
      });
    }
  };

  const [isNewSubmetricModal, setAddNewSubmetricModal] = useState(false);

  const showModal = () => {
    setAddNewSubmetricModal(true);
  };

  const handleOk = async (subMetricName) => {
    // console.log("Input 1:", subMetricName);
    const payload = {
      metrictype: submetricSelectedType,
      metricname: subMetricName,
    };
    const url = `customize-metric`;
    try {
      const response = await postApi(url, payload);
      if (response?.statusCode === 200) {
        notification.success({
          message: response.message,
        });
        const url = `getAllSubMetric?metric=${submetricSelectedType}`;
        getInventorySubMetricLists(url);
        setAddNewSubmetricModal(false);
      }
    } catch (error) {
      console.error("SignIn failed:", error.message);
      notification.error({
        message: error.message,
      });
    }
  };

  const handleCancel = () => {
    setAddNewSubmetricModal(false);
  };

  return (
    <>
      {!selectedMetric && !isShowEscalationList && (
        <div className="flex" style={{ height: "100vh" }}>
          <div
            className="w-1/4 flex flex-col border border-gray-300 m-2 rounded-md overflow-hidden"
            style={{ minHeight: "200px" }}
          >
            <div className="p-3 flex items-center justify-between border-b border-gray-300 sticky top-0 bg-white z-10">
              <h2 className="cus-h2-heading">
                {submetricSelectedType} Sub Metrics
              </h2>
              <FiPlusCircle
                className="text-xl cursor-pointer"
                onClick={showModal}
              />
            </div>
            <div className="flex-1 p-1 overflow-auto">
              {metrics.map((metric, index) => (
                <div
                  key={index}
                  draggable
                  onDragStart={(e) => onDragStart(e, metric)}
                  className="p-2 m-2 border border-gray-200 rounded-lg bg-gradient-to-r from-blue-50 to-white shadow-md cursor-pointer transition-transform transform hover:scale-105 hover:bg-blue-50"
                >
                  <div className="com-value text-center">
                    {metric.metricname}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div
            className="w-3/4 flex flex-col border border-gray-300 m-2 rounded-md overflow-auto"
            onDrop={onDrop}
            onDragOver={allowDrop}
            style={{ maxHeight: "100vh" }}
          >
            <div className="p-6 border border-gray-200 rounded-lg shadow-lg bg-gradient-to-r from-blue-50 to-white mt-4 mb-4 transition-transform transform hover:scale-105 w-48 h-36 flex items-center justify-center mx-auto">
              <div className="flex justify-between items-center mb-3">
                <div className="com-heading transition-colors">
                  {submetricSelectedType}
                </div>
              </div>
            </div>

            <div className="flex-1 p-3 overflow-auto">
              {droppedMetrics.length === 0 ? (
                <p className="text-gray-500">Drag and drop metrics here</p>
              ) : (
                <div
                  className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"
                  style={{ gridAutoRows: "minmax(150px, auto)" }}
                >
                  {droppedMetrics.map((metric, index) => (
                    <div
                      key={index}
                      className="flex flex-col p-6 border border-gray-200 rounded-lg shadow-md bg-gradient-to-r from-blue-50 to-white transition-transform transform hover:scale-105 mb-4"
                    >
                      <div className="flex justify-between items-center mb-3">
                        <div
                          className="com-heading cursor-pointer hover:text-[#64aaeb] transition-colors font-medium"
                          onClick={() => handleCardClick(metric)}
                        >
                          {metric.metricname}
                        </div>
                        <button
                          className="text-red-600 hover:text-red-800 transition-colors p-1 rounded-full hover:bg-red-100"
                          onClick={() => handleRemove(metric)}
                        >
                          <IoClose size={22} />
                        </button>
                      </div>
                      <div className="com-value text-gray-800">
                        {metric.value}
                      </div>
                      <div className="com-value mt-2 text-gray-600">
                        Weightage: {metric.Weightage}%
                      </div>
                      {/* <div className="mt-auto ml-4">
                        <Button
                          className="mt-2 bg-blue-500 text-white hover:bg-blue-600 transition-colors"
                          onClick={() => {
                            setIsShowEscalationList(true);
                            setSelectedMetricForEscalation(metric);
                          }}
                        >
                          Escalation
                        </Button>
                      </div> */}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {selectedMetric && (
        <SubMetrics
          selSubMetric={selectedMetric}
          handleBackClick={handleBackClick}
          setConfigureStarted={setConfigureStarted}
        />
      )}

      {isNewSubmetricModal && (
        <AddNewSubmetricModal
          isNewSubmetricModal={isNewSubmetricModal}
          handleOk={handleOk}
          handleCancel={handleCancel}
          submetricSelectedType={submetricSelectedType}
        />
      )}

      {isShowEscalationList && (
        <ConfigureEscalation
          setIsShowEscalationList={setIsShowEscalationList}
        />
      )}
    </>
  );
};

export default Inventory;
